import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../firebase/auth.tsx";
import { API_BASE_URL } from "../config.tsx";
import "../styles/main.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { useTranslation } from "react-i18next";

import { getTranslatedText } from './helpers/translationMap';

const Main: React.FC = () => {
  const { userId, language } = useAuth();

  const [trainingHistory, setTrainingHistory] = useState<any[]>([]);
  const [filteredTrainingHistory, setFilteredTrainingHistory] = useState<any[]>(
    []
  );
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [adminView, setAdminView] = useState(false);
  const [headquarterView, setHeadquarterView] = useState(false);
  const [groupView, setGroupView] = useState(false);
  const [filterPanels, setFilterPanels] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [searchQueries, setSearchQueries] = useState<{ [key: string]: string }>(
    {}
  );
  const [baseColumnMultiplicator, setBaseColumnMultiplicator] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const panelRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await showTrainingsHistory(userId);
      setLoading(false);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        Object.keys(panelRefs.current).some((key) => {
          const panel = panelRefs.current[key];
          return panel && !panel.contains(event.target as Node);
        })
      ) {
        setFilterPanels({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // It should be before apply filters to update filtered list correctly
  useEffect(() => {
    setFilteredTrainingHistory(trainingHistory.slice(0, currentPage * 100));
  }, [trainingHistory, currentPage]);

  useEffect(() => {
    applyFilters();
  }, [filters, currentPage]);

  const showTrainingsHistory = async (userId: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/getTrainingsHistory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to get trainings history");
      }

      const trainingsData = await response.json();

      if (!trainingsData || trainingsData.length === 0) {
        setTrainingHistory([]);
        setFilteredTrainingHistory([]);
        return;
      }

      setTrainingHistory(trainingsData.history);
      setFilteredTrainingHistory(trainingsData.history);

      switch (trainingsData.view) {
        case "admin":
          setAdminView(true);
          setBaseColumnMultiplicator(10);
        case "headquarterAdmin":
          setHeadquarterView(true);
          setBaseColumnMultiplicator(9);
        case "groupAdmin":
          setGroupView(true);
          setBaseColumnMultiplicator(8);
      }
    } catch (error) {
      console.error("Error getting trainings history:", error);
      throw new Error("An error occurred while getting trainings history");
    }
  };

  const handleRowClick = (index: number) => {
    setSelectedRow(filteredTrainingHistory[index]);
  };

  const getLocalizedResult = (value) => {
    if (typeof value !== "string") {
      return value || "";
    }

    const match = value.match(/Failed\s\((\d+)\/(\d+)\)/);
    if (match) {
      const current = match[1];
      const total = match[2];
      return t("FailedWithNumbers", { current, total });
    }
    return t(value);
  };

  const toggleFilterPanel = (column: string) => {
    setFilterPanels((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as { [key: string]: boolean });
      return {
        ...newState,
        [column]: !prevState[column],
      };
    });
    setSearchQueries((prevState) => ({
      ...prevState,
      [column]: "",
    }));
  };

  const handleFilterChange = (column: string, value: string) => {
    setFilters((prevState) => {
      const columnFilters = prevState[column] || [];
      if (columnFilters.includes(value)) {
        return {
          ...prevState,
          [column]: columnFilters.filter((v) => v !== value),
        };
      } else {
        return {
          ...prevState,
          [column]: [...columnFilters, value],
        };
      }
    });
  };

  const applyFilters = () => {
    const filteredData = trainingHistory.filter((record) => {
      return Object.keys(filters).every((column) => {
        if (filters[column].length === 0) {
          return true;
        }
        let recordValue;
        if (column === "trainingResult") {
          recordValue = record.trainingResultValue;
        } else {
          recordValue = record[column];
        }
        return filters[column].includes(recordValue?.toString());
      });
    });
    setFilteredTrainingHistory(filteredData.slice(0, currentPage * 100));
  };

  const handleFilterSearch = (column: string, query: string) => {
    setSearchQueries((prevState) => ({
      ...prevState,
      [column]: query,
    }));
  };

  const renderFilterPanel = (column: string, lastColumn: boolean = false) => {
    const query = searchQueries[column]?.toLowerCase() || "";
    let uniqueValues: string[] = [];

    if (column === "trainingResult") {
      uniqueValues = Array.from(
        new Set(
          trainingHistory.map((record) =>
            getLocalizedResult(record.trainingResultValue)
          )
        )
      ).filter(
        (value) =>
          value != null && value.toString().toLowerCase().includes(query)
      );
    } else {
      uniqueValues = Array.from(
        new Set(trainingHistory.map((record) => t(record[column])))
      ).filter(
        (value) =>
          value != null && value.toString().toLowerCase().includes(query)
      );
    }

    uniqueValues = uniqueValues.filter((value) => value !== undefined);

    uniqueValues.sort((a, b) => a.localeCompare(b));

    const allChecked = filters[column]?.length === uniqueValues.length;

    const handleSelectAll = (checked: boolean) => {
      const allValues = uniqueValues.filter((value) => value !== undefined);
      if (checked) {
        setFilters((prevState) => ({
          ...prevState,
          [column]: allValues,
        }));
      } else {
        setFilters((prevState) => ({
          ...prevState,
          [column]: [],
        }));
      }
    };

    return (
      <div
        ref={(el) => (panelRefs.current[column] = el)}
        className={lastColumn ? "filter-last-panel" : "filter-panel"}
        style={{
          maxHeight: "calc(100vh / 2)",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#EFF0F2 transparent",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <input
            type="text"
            placeholder={t("search")}
            style={{
              width: "94%",
              backgroundColor: "#eff0f2",
              border: "none",
              outline: "none",
              borderRadius: "10px",
              color: "#404040",
              height: "2.7vh",
              maxHeight: "30px",
              fontSize: "1em",
              paddingLeft: "10px",
              marginTop: "10px",
            }}
            value={query}
            onChange={(e) => handleFilterSearch(column, e.target.value)}
          />
        </div>
        {query === "" && (
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              marginBottom: "1vh",
              marginTop: "1vh",
            }}
          >
            <li
              key={"selectAll"}
              className="filters-form-check filters-checkbox"
            >
              <label
                className="filters-checkbox-label"
                style={{ display: "inline-block" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agreeCheckbox"
                  checked={allChecked}
                  onChange={(e) => {
                    handleSelectAll(e.target.checked);
                  }}
                  style={{ display: "none" }}
                />
                <span
                  className="filters-checkmark"
                  style={{ backgroundColor: "white" }}
                ></span>
                <span className="form-check-label">{t("Select_all")}</span>
              </label>
            </li>
          </ul>
        )}
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            marginBottom: "1vh",
            marginTop: "1vh",
          }}
        >
          {uniqueValues.map((value) => (
            <li key={value} className="filters-form-check filters-checkbox">
              <label
                className="filters-checkbox-label"
                style={{ display: "inline-block" }}
              >
                <input
                  type="checkbox"
                  checked={filters[column]?.includes(value.toString()) || false}
                  onChange={() => handleFilterChange(column, value.toString())}
                />
                <span
                  className="filters-checkmark"
                  style={{ backgroundColor: "white" }}
                ></span>
                <span className="form-check-label">
                  {value ? value : t("none")}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderTrainingDetailsModal = () => {
    if (!selectedRow) return null;

    let trainingResultObject = null;

    if (selectedRow.trainingResult) {
      try {
        trainingResultObject = JSON.parse(selectedRow.trainingResult);
      } catch (error) {
        console.error("Invalid JSON format:", error);
        trainingResultObject = null;
      }
    }

    return (
      <div className="confirmation-modal">
        <div className="modal-content">
          <p>{t("Training details")}</p>
          <br />
          <div>
            {t("Device name")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {selectedRow.trainingName}
            </span>
          </div>
          <div>
            {t("Training type")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {t(selectedRow.trainingType)}
            </span>
          </div>
          <div>
            {t("Start_time")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {selectedRow.trainingTime}
            </span>
          </div>
          <div>
            {t("Duration")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {selectedRow.trainingDuration}
            </span>
          </div>
          <div>
            {t("Mode")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {t(selectedRow.trainingMode)}
            </span>
          </div>
          <div>
            {t("Result")}:{" "}
            <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
              {getLocalizedResult(selectedRow.trainingResultValue)}
            </span>
          </div>
          {trainingResultObject &&
            trainingResultObject.results &&
            trainingResultObject.results.length > 0 &&
            trainingResultObject.results[0].text !== "noCounter" && (
              <>
                <br />
                <div>
                  {t("Details")}:
                  <ul>
                    {trainingResultObject.results.map((result, index) => (
                      <li
                        key={index}
                        style={{ color: result.result ? "#44963C" : "#A63939" }}
                      >
                        {getTranslatedText(result.text, t)}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

          <div className="button-container">
            <button
              type="button"
              className="buttonCancel btn btn-primary"
              onClick={() => setSelectedRow(null)}
            >
              {t("Close")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main-container">
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "0.7rem",
          }}
        >
          {t("Loading...")}
        </div>
      ) : (
        <>
          <div
            style={{
              marginTop: "80px",
              marginLeft: "50px",
              marginRight: "40px",
              height: "100%",
              minHeight: "calc(100vh - 120px)",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#39A686 transparent",
            }}
          >
            {/* Separate table header - don't find other way to avoid overlapping header on scroll */}
            <div
              style={{
                position: "sticky",
                top: "0",
                zIndex: "1",
                backgroundColor: "transparent",
                border: "none",
                marginLeft: "12px",
                marginRight: "11px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <table
                className="table"
                style={{
                  borderColor: "transparent",
                  marginBottom: "0px",
                  tableLayout: "fixed",
                  color: "#39A686",
                }}
              >
                <thead>
                  <tr>
                    {(adminView || headquarterView || groupView) && (
                      <th
                        scope="col"
                        style={{
                          width: `calc(100% / ${baseColumnMultiplicator})`,
                          fontWeight: "normal",
                        }}
                      >
                        <span
                          onClick={() => toggleFilterPanel("firstName")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {t("User")}
                          <span
                            style={{
                              fontSize: "1.3em",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            &#9013;
                          </span>
                        </span>
                        {filterPanels["firstName"] &&
                          renderFilterPanel("firstName")}
                      </th>
                    )}
                    {adminView && (
                      <th
                        scope="col"
                        style={{
                          width: `calc(100% / ${baseColumnMultiplicator - 1})`,
                          fontWeight: "normal",
                        }}
                      >
                        <span
                          onClick={() => toggleFilterPanel("headquarterName")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {t("Headquarter")}
                          <span
                            style={{
                              fontSize: "1.3em",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            &#9013;
                          </span>
                        </span>
                        {filterPanels["headquarterName"] &&
                          renderFilterPanel("headquarterName")}
                      </th>
                    )}
                    {(adminView || headquarterView) && (
                      <th
                        scope="col"
                        style={{
                          width: `calc(100% / ${baseColumnMultiplicator - 1})`,
                          fontWeight: "normal",
                        }}
                      >
                        <span
                          onClick={() => toggleFilterPanel("groupName")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {t("Hospital")}
                          <span
                            style={{
                              fontSize: "1.3em",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            &#9013;
                          </span>
                        </span>
                        {filterPanels["groupName"] &&
                          renderFilterPanel("groupName")}
                      </th>
                    )}
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator - 1})`,
                        fontWeight: "normal",
                      }}
                    >
                      <span
                        onClick={() => toggleFilterPanel("trainingName")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {t("Device_name")}
                        <span
                          style={{
                            fontSize: "1.3em",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          &#9013;
                        </span>
                      </span>
                      {filterPanels["trainingName"] &&
                        renderFilterPanel("trainingName")}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator - 3})`,
                        fontWeight: "normal",
                      }}
                    >
                      <span
                        onClick={() => toggleFilterPanel("trainingType")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {t("Training_type")}
                        <span
                          style={{
                            fontSize: "1.3em",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          &#9013;
                        </span>
                      </span>
                      {filterPanels["trainingType"] &&
                        renderFilterPanel("trainingType")}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator - 3})`,
                        fontWeight: "normal",
                      }}
                    >
                      {t("Start_time")}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator + 4})`,
                        fontWeight: "normal",
                      }}
                    >
                      {t("Duration")}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator + 4})`,
                        fontWeight: "normal",
                      }}
                    >
                      <span
                        onClick={() => toggleFilterPanel("trainingMode")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {t("Mode")}
                        <span
                          style={{
                            fontSize: "1.3em",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          &#9013;
                        </span>
                      </span>
                      {filterPanels["trainingMode"] &&
                        renderFilterPanel("trainingMode")}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: `calc(100% / ${baseColumnMultiplicator + 4})`,
                        fontWeight: "normal",
                      }}
                    >
                      <span
                        onClick={() => toggleFilterPanel("trainingResult")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {t("Result")}
                        <span
                          style={{
                            fontSize: "1.3em",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          &#9013;
                        </span>
                      </span>
                      {filterPanels["trainingResult"] &&
                        renderFilterPanel("trainingResult", true)}
                    </th>
                    <th className="detailsButtonWrapper"></th>
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table with restricted height and scrollbar */}
            <div
              style={{
                maxHeight: "calc(100vh - 165px)",
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "#39A686 transparent",
              }}
            >
              <div className="container-fluid" style={{ paddingRight: "0px" }}>
                <table
                  className="table"
                  style={{
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    {filteredTrainingHistory.map(
                      (trainingRecord: any, index: number) => (
                        <tr
                          key={`${trainingRecord.trainingTime}-${trainingRecord.trainingId}-${index}`}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#D4E5E2" : "transparent",
                          }}
                        >
                          {(adminView || headquarterView || groupView) && (
                            <td
                              style={{
                                width: `calc(100% / ${baseColumnMultiplicator})`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                            >
                              <div className="containerHeader">
                                <img
                                  src="/images/Icon_User_Simple.png"
                                  style={{
                                    width: "2vh",
                                    minWidth: "10px",
                                    marginLeft: "0.3vh",
                                    marginRight: "1.5vh",
                                  }}
                                />
                                <div className="userName">
                                  {trainingRecord.firstName}
                                  <br />
                                  {trainingRecord.lastName}
                                </div>
                              </div>
                            </td>
                          )}
                          {adminView && (
                            <td
                              style={{
                                width: `calc(100% / ${
                                  baseColumnMultiplicator - 1
                                })`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                            >
                              {trainingRecord.headquarterName}
                            </td>
                          )}
                          {(adminView || headquarterView) && (
                            <td
                              style={{
                                width: `calc(100% / ${
                                  baseColumnMultiplicator - 1
                                })`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                                lineHeight: 1,
                              }}
                            >
                              {trainingRecord.groupName}
                              {trainingRecord.groupName && (
                                <span
                                  style={{
                                    color: "#39a686",
                                  }}
                                >
                                  &nbsp;|{" "}
                                  {t("country_" + trainingRecord.countryCode)}
                                </span>
                              )}
                            </td>
                          )}
                          <td
                            style={{
                              width: `calc(100% / ${
                                baseColumnMultiplicator - 1
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {trainingRecord.trainingName}
                          </td>
                          <td
                            style={{
                              width: `calc(100% / ${
                                baseColumnMultiplicator - 3
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {t(trainingRecord.trainingType)}
                          </td>
                          <td
                            style={{
                              width: `calc(100% / ${
                                baseColumnMultiplicator - 3
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {trainingRecord.trainingTime}
                          </td>
                          <td
                            style={{
                              width: `calc(100% / ${
                                baseColumnMultiplicator + 4
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {trainingRecord.trainingDuration}
                          </td>
                          <td
                            style={{
                              width: `calc(100% / ${
                                baseColumnMultiplicator + 4
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {t(trainingRecord.trainingMode)}
                          </td>
                          <td
                            style={{
                              color: trainingRecord.totalResult
                                ? "#44963C"
                                : "#A63939",
                              width: `calc(100% / ${
                                baseColumnMultiplicator + 4
                              })`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                              lineHeight: 1,
                            }}
                          >
                            {getLocalizedResult(
                              trainingRecord.trainingResultValue
                            )}
                          </td>
                          <td className="detailsButtonWrapper">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handleRowClick(index)}
                            >
                              {t("Details")}
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {filteredTrainingHistory.length < trainingHistory.length && (
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      style={{
                        backgroundColor: "#39A686",
                        borderColor: "#39A686",
                        borderRadius: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        fontSize: "1em",
                      }}
                    >
                      {t("Show_More")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {selectedRow && renderTrainingDetailsModal()}
          </div>
        </>
      )}{" "}
    </div>
  );
};

export default Main;
