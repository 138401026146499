import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import Handlebars from "handlebars";

// Initialize i18next
i18n
  .use(HttpBackend) // Loads translations from your backend/files
  .use(initReactI18next) // Passes i18n to react-i18next
  .init({
    fallbackLng: "en", // Default language if no match is found
    debug: false, // Enable debug mode (optional)
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    backend: {
      loadPath: "/localization/{{lng}}/{{ns}}.json", // Path to translation files
    },
  });

// Register Handlebars helper for translations
Handlebars.registerHelper("t", function (key, options) {
  return i18n.t(key, options?.hash || {});
});

export default i18n;
