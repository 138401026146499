import { TFunction } from "i18next";
import Handlebars from "handlebars";

const wrongIndicators = ["schlecht", "špatně", "wrong", "不好", "male", "плохо", "mal", "pauvrement", "forkert"];

const labelVariants: Record<string, { labels: string[]; context?: boolean; }> = {
  therapy_type: { labels: ["TherapyType", "Therapy type", "Therapietyp", "Typ terapie", "治疗类型", "Tipo di terapia", "Тип терапии", "Tipo de terapia", "Type de thérapie", "Terapi type"]},
  anticoagulation: { labels: ["AnticoagulationType", "Anticoagulation type", "Antikoagulationstyp", "Typ antikoagulace", "抗凝型", "Tipo anticoagulante", "Тип антикоагуляции", "Tipo de anticoagulación", "Type d'anticoagulant", "Tipo de anticoagulação", "Antikoagulationstype"]},
  anticoagulation_none: {labels: ["anticoagulation_none"]},
  anticoagulation_heparin: {labels: ["anticoagulation_heparin"]},
  anticoagulation_citrate_calcium: {labels: ["anticoagulation_citrate_calcium"]},
  syringe_type: { labels: ["SyringeType", "Syringe type", "Spritzentyp", "Typ stříkačky", "注射器类型", "Tipo di siringa", "Тип шприца", "Tipo de jeringa", "Type de seringue", "Tipo de seringa", "Sprøjte type"]},
  syringe_volume: { labels: ["SyringeVolume", "Syringe volume", "Spritzenvolumen", "Objem stříkačky", "注射器容量", "Volume della siringa", "Объем шприца", "Volumen de la jeringa", "Volume de la seringue", "Volume da seringa", "Sprøjtevolumen"]},
  patient_weight: { labels: ["PatientWeight", "Patient weight", "Patientengewicht", "Hmotnost pacienta", "患者体重", "Peso del paziente", "Вес пациента", "Peso del paciente", "Poids du patient", "Peso do paciente", "Patientvægt"]},
  net_fluid_removal: { labels: ["NetFluidRemoval", "Net fluid removal", "Netto-Flüssigkeitsentfernung", "Čistý odvod kapaliny", "净液体去除量", "Rimozione netta del fluido", "Чистое удаление жидкости", "Eliminación neta de fluidos", "Élimination nette du liquide", "Remoção líquida de fluido", "Fjernelse af netvæske"]},
  dyalisate_dose: { labels: ["DialysateDose", "Dyalisate dose", "Dyalisat-Dosis", "Dávka dyalisátu", "磷酸盐剂量", "Dose di dializzare", "Доза диализата", "Dosis de dializado", "Dose de dyalisate", "Dose de dialisato", "Dyalisat dosis"]},
  citrate_dose: { labels: ["CitrateDose", "Citrate dose", "Citrat-Dosis", "Dávka citrátu", "柠檬酸盐剂量", "Dose di citrato", "Доза цитрата", "Dosis de citrato", "Dose de citrate", "Dose de citrato", "Citrat dosis"]},
  calcium_dose: { labels: ["CalciumDose", "Calcium dose", "Calciumdosis", "Dávka kalcia", "钙剂量", "Dose di calcio", "Доза кальция", "Dosis de calcio", "Dose de calcium", "Dose de cálcio", "Calcium dosis"]},
  patient_connection: { labels: ["PatientConnection", "Patient connection", "Patientenanbindung", "Napojení pacienta", "患者连接", "Connessione al paziente", "Подключение пациента", "Conexión del paciente", "Connexion avec les patients", "Conexão do paciente", "Patientforbindelse"]},
  patient_connection_white: { labels: ["patient_connection_white"] },
  patient_connection_red: { labels: ["patient_connection_red"] },
  substitution_post: { labels: ["SubstitutionPost", "Substitution \\(post\\)", "Substituce \\(post\\)", "Vertretung \\(Post\\)", "替补（后）", "Sostituzione \\(post\\)", "Замена \\(пост\\)", "Sustitución \\(post\\)", "Remplacement \\(poste\\)", "Substituição \\(postagem\\)", "Udskiftning \\(post\\)"]},
  substitution_pre: { labels: ["SubstitutionPre", "Substitution \\(pre\\)", "Substituce \\(pre\\)", "Vertretung \\(vor\\)", "替补（前）", "Sostituzione \\(pre\\)", "Замена \\(предварительно\\)", "Sustitución \\(pre\\)", "Remplacement \\(pré\\)", "Substituição \\(pré\\)"]},
  heparin_flow_rate: { labels: ["HeparinFlowRate", "Heparin flow rate", "Průtok heparinu", "Heparin-Flussrate", "肝素流速", "Portata di eparina", "Скорость потока гепарина", "Caudal de heparina", "Débit d'héparine", "Taxa de fluxo de heparina", "Heparin flowhastighed"]},
  dilution: { labels: ["DilutionType", "Dilution", "Ředění", "Verdünnung", "稀释", "Diluizione", "Разбавление", "Dilución", "Dilution", "Diluição"]},
  post_dilution: { labels: ["post_dilution"] },
  pre_dilution: { labels: ["pre_dilution"] },
  pre_post_dilution: { labels: ["pre_post_dilution"] },
  post_post_dilution: { labels: ["post_post_dilution"] },
  wrongly_grabbed_objects: { labels: ["GrabMistakes", "Wrongly grabbed objects", "Špatně uchopené objekty", "Schlecht gegriffene Gegenstände", "错误地抓取了物体", "Oggetti scarsamente afferrati", "Плохо захватываемые предметы", "Objetos mal agarrados", "Objets mal saisis", "Objetos mal agarrados", "Forkert greb genstande"]},
  plasma_substitution_volume: { labels: ["PlasmaSubstitutionVolume", "Plasma substitution volume", "Objem náhrady plazmy", "Plasmasubstitutionsvolumen", "血浆置换量", "Volume di sostituzione plasmatica", "Объем плазмозамещения", "Volumen de sustitución de plasma", "Volume de substitution plasmatique", "Volume de substituição plasmática"]},
  plasma_filtration_ratio: { labels: ["PlasmaFiltrationRatio", "Plasma filtration ratio", "Poměr plazmové filtrace", "Plasmafiltrationsverhältnis", "血浆过滤比", "Rapporto di filtrazione del plasma", "Коэффициент фильтрации плазмы", "Relación de filtración de plasma", "Taux de filtration du plasma", "Taxa de filtração de plasma"]},
  wrongly_chosen_therapies: { labels: ["SelectedTherapy", "Wrongly chosen therapies", "Špatně zvolených terapií", "Schlecht gewählte Therapien", "治疗方法选择不当", "Terapie mal scelte", "Неудачно выбранная терапия", "Terapias mal elegidas", "Des thérapies mal choisies", "Terapias mal escolhidas"]},
  gender: { labels: ["Gender", "Pohlaví", "Geschlecht", "性别", "Genere", "Пол", "Género", "Genre", "Gênero"]},
  gender_female: { labels: ["gender_female"] },
  gender_male: { labels: ["gender_male"] },
  patient_height: { labels: ["PatientHeight", "Patient height", "Výška pacienta", "Patientengröße", "患者身高", "Altezza del paziente", "Рост пациента", "Altura del paciente", "Taille du patient", "Altura do paciente"]},
  mode: { labels: ["Mode", "Režim", "Modus", "模式", "Modalità", "Режим", "Modo", "Mode", "Modo"]},
  oxygen_level: { labels: ["OxygenLevel", "Oxygen level", "Hladina kyslíku", "Sauerstoffgehalt", "含氧量", "Livello di ossigeno", "Уровень кислорода", "Nivel de oxígeno", "Niveau d'oxygène", "Nível de oxigênio"]},
  of_blood: { labels: ["OfBlood", "of blood", "krve", "aus Blut", "血的", "di sangue", "крови", "de sangre", "de sang", "de sangue", "af blod"], context: true },
  of_effluent: { labels: ["OfEffluent", "of effluent", "výtoku", "von Abwasser", "废水量", "di effluente", "сточных вод", "de efluente", "d'effluent", "de efluente", "af spildevand"], context: true }
};

const patientConnectionVariants: Record<string, string[]> = {
  patient_connection_white: [
    "Patient connection: White connection", 
    "Patientenanbindung: Weiße Verbindung", 
    "Napojení pacienta: Bílé připojení", 
    "患者连接: 白色连接", 
    "Connessione al paziente: Connessione bianca", 
    "Подключение пациента: Белое соединение", 
    "Conexión del paciente: Conexión blanca", 
    "Connexion avec les patients: Connexion blanche", 
    "Conexão do paciente: Conexão branca", 
    "Patientforbindelse: Hvid forbindelse",
    "patient_connection_white"
  ],
  patient_connection_red: [
    "Patient connection: Red connection", 
    "Patientenanbindung: Rote Verbindung", 
    "Napojení pacienta: Červené připojení", 
    "患者连接: 红色连接", 
    "Connessione al paziente: Collegamento rosso", 
    "Подключение пациента: Красное соединение", 
    "Conexión del paciente: Conexión roja", 
    "Connexion avec les patients: Connexion rouge", 
    "Conexão do paciente: Conexão vermelha", 
    "Patientforbindelse: Rød forbindelse",
    "patient_connection_red"
  ]
};

const anticoagulationVariants: Record<string, string[]> = {
  anticoagulation_none: [
    "Anticoagulation type: None", 
    "Antikoagulationstyp: Keiner", 
    "Typ antikoagulace: Žádný", 
    "抗凝型: 没有任何", 
    "Tipo anticoagulante: Nessuno", 
    "Тип антикоагуляции: Никто", 
    "Tipo de anticoagulación: Ninguno", 
    "Type d'anticoagulant: Aucun", 
    "Tipo de anticoagulação: Nenhum", 
    "Antikoagulationstype: Ingen",
    "Anticoagulación: Ninguna",
    "anticoagulation_none"
  ],
  anticoagulation_heparin: [
    "Anticoagulation type: Heparin", 
    "Antikoagulationstyp: Heparin", 
    "Typ antikoagulace: Heparin", 
    "抗凝型: 肝素", 
    "Tipo anticoagulante: Eparina", 
    "Тип антикоагуляции: Гепарин", 
    "Tipo de anticoagulación: heparina", 
    "Type d'anticoagulant: Héparine", 
    "Tipo de anticoagulação: Heparina", 
    "Antikoagulationstype: Heparin",
    "anticoagulation_heparin"
  ],
  anticoagulation_citrate_calcium: [
    "Anticoagulation type: Citrate - Calcium", 
    "Antikoagulationstyp: Citrat - Calcium", 
    "Typ antikoagulace: Citrát - kalcium", 
    "抗凝型: 枸橼酸-钙", 
    "Tipo anticoagulante: Citrato - Calcio", 
    "Тип антикоагуляции: Цит. - кальц.", 
    "Tipo de anticoagulación: Citrato - Calcio", 
    "Type d'anticoagulant: Citrate - Calcium", 
    "Tipo de anticoagulação: Citrato - Cálcio", 
    "Antikoagulationstype: Citrat - Calcium",
    "anticoagulation_citrate_calcium"
  ]
};

const genderVariants: Record<string, string[]> = {
  gender_female: [
    "Gender: Female", 
    "Pohlaví: Žena", 
    "Geschlecht: Frau", 
    "性别: 女士", 
    "Genere: Donna", 
    "Пол: Женщина", 
    "Género: Mujer", 
    "Genre: Femme", 
    "Gênero: Mulher",
    "gender_female"
  ],
  gender_male: [
    "Gender: Male", 
    "Pohlaví: Muž", 
    "Geschlecht: Mann", 
    "性别: 男人", 
    "Genere: Uomo", 
    "Пол: Мужчина", 
    "Género: Hombre", 
    "Genre: Homme", 
    "Gênero: Homem",
    "gender_male"
  ]
};

const dilutionVariants: Record<string, string[]> = {
  post_dilution: [
    "Dilution: Post Dilution", 
    "Ředění: Postdiluce", 
    "Verdünnung: Post-Dilution", 
    "稀释: 后稀释", 
    "Diluizione: Post Diluizione", 
    "Разбавление: Постдилюция", 
    "Dilución: Post dilución", 
    "Dilution: Post-dilution", 
    "Diluição: Pós -diluição", 
    "Efterfortynding: Post diluent",
    "post_dilution"
  ],
  pre_dilution: [
    "Dilution: Pre Dilution", 
    "Ředění: Prediluce", 
    "Verdünnung: Pre-Dilution", 
    "稀释: 预稀释", 
    "Diluizione: Pre Diluizione", 
    "Разбавление: Предилюция", 
    "Dilución: Pre dilución", 
    "Dilution: Pré-dilution", 
    "Diluição: Pré -diluição", 
    "Efterfortynding: Pre-diluent",
    "pre_dilution"
  ],
  pre_post_dilution: [
    "Dilution: Pre-Post Dilution", 
    "Ředění: Pre-Post Diluce", 
    "Verdünnung: Pre-Post-Dilution", 
    "稀释: 前-后稀释", 
    "Diluizione: Pre-Post Diluizione", 
    "Разбавление: Пре-Постдилюция", 
    "Dilución: Pre-Post dilución", 
    "Dilution: Dilution pré-post", 
    "Diluição: Diluição pré-post", 
    "Efterfortynding: Pre-Post Dilution",
    "pre_post_dilution"
  ],
  post_post_dilution: [
    "Dilution: Post-Post Dilution", 
    "Ředění: Post-Post Diluce", 
    "Verdünnung: Post-Post-Dilution", 
    "稀释: 后-后稀释", 
    "Diluizione: Post-Post Diluizione", 
    "Разбавление: Пост-Постдилюция", 
    "Dilución: Post-Post dilución", 
    "Dilution: Post-post dilution", 
    "Diluição: Pós-pós diluição", 
    "Dilution: Post-Post Fortynding",
    "post_post_dilution"
  ]
};


const resolveVariantKey = (key: string, text: string, variants: Record<string, string[]>) => {
  const match = Object.entries(variants).find(([_, labels]) => labels.some((label) => text.includes(label)));
  return match ? `training.${match[0]}` : key;
};

// Extract contextVariants dynamically
const contextVariants = Object.entries(labelVariants)
  .filter(([_, data]) => data.context)
  .reduce((acc, [key, data]) => ({ ...acc, [key]: data.labels }), {} as Record<string, string[]>);

// Generate regex pattern dynamically for context variants
const contextRegex = Object.values(contextVariants)
  .flat()
  .map(phrase => phrase.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
  .join("|");


const textPatterns = Object.entries(labelVariants).map(([key, data]) => {
  const labelRegex = data.labels.join("|");
  const wrongRegex = wrongIndicators.join("|");

  return {
    regex: new RegExp(`^(${labelRegex}):\\s*([\\s\\S]+?)\\s*(${contextRegex})?(?:\\s*\\((${wrongRegex})\\))?$`),
    key: `training.${key}`
  };
});

const textPatterns2 = Object.entries(labelVariants).map(([key, data]) => {
  const labelRegex = data.labels.join("|");
  const wrongRegex = wrongIndicators.join("|");

  return {
    regex: new RegExp(`^(${labelRegex}):\\s*([^()]+?)(?:\\s*\\((${wrongRegex})\\))?$`),
    key: `training.${key}`
  };
});

export const getTranslatedText = (text: string, t: TFunction): string => {
  const isVariantText = (variants: Record<string, string[]>) =>
    Object.values(variants).some(labels => labels.some(label => text.includes(label)));

  const patternsToUse = 
    isVariantText(patientConnectionVariants) || 
    isVariantText(anticoagulationVariants) || 
    isVariantText(genderVariants) ||
    isVariantText(dilutionVariants) 
      ? textPatterns2 
      : textPatterns;

  const translateText = (key: string, parsedContext: any) => {
    let translationKey = key;
    if (key.startsWith('training.')) {
      const variantMappings: Record<string, Record<string, string[]>> = {
        'training.patient_connection': patientConnectionVariants,
        'training.anticoagulation': anticoagulationVariants,
        'training.gender': genderVariants,
        'training.dilution': dilutionVariants
      };
      translationKey = resolveVariantKey(key, text, variantMappings[key] || {});
    }

    const translationTemplate = t(translationKey, { ...parsedContext, interpolation: { escapeValue: false } });

    if (translationTemplate === translationKey) return text;

    return Handlebars.compile(translationTemplate)(parsedContext);
  };

  //console.log(text);

  for (const { regex, key } of patternsToUse) {
    const match = text.match(regex);
    if (!match) continue;

    if (patternsToUse === textPatterns) {
      const [_, label, value, context, wrong] = match;

      const parsedContext = {
        value: value.trim(),
        context: context ? context.trim() : "",
        result: wrong ? "wrong" : null
      };

      if (parsedContext.context) {
        for (const [termKey, variants] of Object.entries(labelVariants)) {
          if (variants.labels.some(variant => parsedContext.context.toLowerCase() === variant.toLowerCase())) {
            parsedContext.context = t(`terms.${termKey}`, { defaultValue: parsedContext.context });
            break;
          }
        }
      }

      //console.log(parsedContext);

      return translateText(key, parsedContext);
    } else if (patternsToUse === textPatterns2) {
      const [_, label, value, wrong] = match;

      const parsedContext = {
        value: value.trim(),
        result: wrong ? "wrong" : null
      };

      //console.log(parsedContext);

      return translateText(key, parsedContext);
    }
  }

  return text;
};

export default textPatterns;
